 /* .btn-group.special {
  display: flex;
}
.special .btn {
  flex: 1
} */
/*<450 dropdown <480 10 <500 10.5 >500 11*/
.btn {
    white-space: nowrap;
    text-align: center!important;
    /* padding:5px!important; */
    vertical-align: middle!important;
    min-height: 0!important;
    height: 20px!important;
    line-height: 5px!important;
    padding: 0!important;
    /* line-height: 40px!important; */
    /* float: none!important;
    display: inline-block!important; */
    min-width: 0!important;
    width: fit-content!important;
    /* transform:scale(0.5)!important; */
  }
  
  /*Theme 1 LABEL BUTTONS*/
  .theme0 .btn{
    background-color: #c4c9cd80;
    color:black!important;
    border-color: #6c757d28;
    border-bottom-width: 1px!important;
  
    border-bottom-color: rgba(255, 255, 255, 0.297)!important;
    border-top-width: 1px!important;
  
    border-top-color: rgba(255, 255, 255, 0.297)!important;
    /* border-left: white!important; */
  }
  .theme0 .leftbtn{
    border-left-color: rgba(255, 255, 255, 0.297)!important;

  }
  .theme0 .rightbtn{
    border-right-color: rgba(255, 255, 255, 0.297)!important;
  
  }
  
  .theme0 .btn.active,.theme3 .btn.active:hover{
    color:white!important;
  
    background-color: #2a2f3280;
    border-color: #6c757d28;
  
  }
  .theme0 .btn:active {
      background-color: #2a2f327f!important;
      border-color: #6c757d28!important;
        box-shadow: none;
  }
  .theme0 .btn:hover{
    color:white!important;
  
    background-color: #3c42467e;
    border-color: #6c757d28;
  
  }
/*Theme 1 LABEL BUTTONS*/
.theme1 .btn{
    background-color: #c4c9cd80;
    color:black!important;
    border-color: #6c757d28;
    border-bottom-width: 1px!important;
  
    border-bottom-color: rgba(255, 255, 255, 0.297)!important;
    border-top-width: 1px!important;
  
    border-top-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme1 .leftbtn{
    border-left-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme1 .rightbtn{
    border-right-color: rgba(255, 255, 255, 0.297)!important;
  
  }
  
  .theme1 .btn.active,.theme3 .btn.active:hover{
    color:black!important;
  
    background-color: #2a2f3241;
    border-color: #6c757d28;
  
  }
  .theme1 .btn:active {
      background-color: #2a2f327f!important;
      border-color: #6c757d28!important;
        box-shadow: none;
  }
  .theme1 .btn:hover{
    color:white!important;
  
    background-color: #3c42467e;
    border-color: #6c757d28;
  
  }
  
  
  /*Theme 2 LABEL BUTTONS*/
  .theme2 .btn{
    background-color: #c4c9cd80;
    color:black!important;
    border-color: #6c757d28;
    border-bottom-width: 1px!important;
  
    border-bottom-color: rgba(255, 255, 255, 0.297)!important;
    border-top-width: 1px!important;
  
    border-top-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme2 .leftbtn{
    border-left-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme2 .rightbtn{
    border-right-color: rgba(255, 255, 255, 0.297)!important;
  
  }
  
  .theme2 .btn.active,.theme3 .btn.active:hover{
    color:white!important;
  
    background-color: #2a2f327f;
    border-color: #6c757d28;
  
  }
  .theme2 .btn:active {
      background-color: #2a2f327f!important;
      border-color: #6c757d28!important;
        box-shadow: none;
  }
  .theme2 .btn:hover{
    background-color: #3c42467e;
    border-color: #6c757d28;
  
  }
  
  /*Theme 3 LABEL BUTTONS*/
  .theme3 .btn{
    background-color: #6c757d28;
    border-color: #6c757d28;
    border-bottom-width: 1px!important;
  
    border-bottom-color: rgba(255, 255, 255, 0.297)!important;
    border-top-width: 1px!important;
  
    border-top-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme3 .leftbtn{
    border-left-color: rgba(255, 255, 255, 0.297)!important;
  }
  .theme3 .rightbtn{
    border-right-color: rgba(255, 255, 255, 0.297)!important;
  
  }
  
  .theme3 .btn.active,.theme3 .btn.active:hover{
    background-color: #2a2f327f;
    border-color: #6c757d28;
  
  }
  .theme3 .btn:active {
      background-color: #2a2f327f!important;
      border-color: #6c757d28!important;
        box-shadow: none;
  }
  .theme3 .btn:hover{
    background-color: #3c42467e;
    border-color: #6c757d28;
  
  }
  
  .btn10{
    font-size: 10px!important;
  }
  .btn105{
    font-size: 10.5px!important;
  }
  .btn11{
  
  
    font-size: 11px!important;
  }
  .d-flex, .btn-group{
    /* justify-content: center!important; */
    /* min-height: 20px!important;
    max-height:20px!important; */
    border: 1px solid black!important;
  
  }