@import "https://code.highcharts.com/css/stocktools/gui.css";
@import "https://code.highcharts.com/css/annotations/popup.css";
.noLabel text {
    fill: red;
    font-weight: normal;
}

.eventConDivChrome{ 
    border: 2px double gold;
    border-radius: 2px;
    outline:4px inset #bbb5c6;
    
    outline-offset: 1px;
   
    margin-left: 5px;
    margin-top: 5px;
}
.eventConDivChrome.light{ 
    border: 2px double #A1ABFF;
    border-right: 1px double #a1aaff41;
    border-radius: 2px;
    outline:3.5px ridge #474d51a8;
    
    outline-offset: 1.7px;
   
    margin-left: 4.8px;
    margin-top: 5px;
}
.eventConDivChrome.light2{ 
    border: 2px solid rgb(54, 54, 54,0.5);
    border-radius: 2px;
    outline:3px ridge #bbb5c6;
    
    outline-offset: 1px;
   
    margin-left: 5px;
    margin-top: 5px;
}
.eventConDivChrome.dark{ 
    border: 2px double #6c6d74;
    border-right: 1px double #a1aaff41;
    border-radius: 2px;
    outline:2px ridge #020303a8;
    
    outline-offset: 1px;
   
    margin-left: 4.8px;
    margin-top: 5px;
}

.eventConDivSafari{
    border: 2px double #D7BE3E;
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid #D7BE3E;

    border-left: 1px solid #D7BE3E;

    margin-left: 2.5px;
    margin-top: 5px;

        position: relative;
        border-radius: 6px;
}
.eventConDivSafari.light{
    border: 2px double #9AA1E1;
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid #9AA1E1;

    border-left: 1px solid #9AA1E1;

    margin-left: 2.5px;
    margin-top: 5px;

        position: relative;
        border-radius: 6px;
}
.eventConDivSafari.light2{
    border: 2px double rgb(54, 54, 54);
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid rgb(54, 54, 54);

    border-left: 1px solid rgb(54, 54, 54);

    margin-left: 2.5px;
    margin-top: 5px;

        position: relative;
        border-radius: 6px;
}
.eventConDivSafari.dark{
    border: 2px double #6c6d74;
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid #6c6d74;

    border-left: 1px solid #6c6d74;

    margin-left: 2.5px;
    margin-top: 5px;

        position: relative;
        border-radius: 6px;
}
.eventConDivSafari::before {
    content: "";
    position: absolute;
    top: -6.25px; 
    right: -5px;
    bottom: -7px; 
    left: -6px; 
    border: 4px ridge #bbb5c6;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }
  .eventConDivSafari.light::before {
    content: "";
    position: absolute;
    top: -5px; 
    right: -6px;
    bottom: -5px; 
    left: -4.5px; 
    border: 3px ridge #474d51c0;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }
  .eventConDivSafari.light2::before {
    content: "";
    position: absolute;
    top: -6.25px; 
    right: -5px;
    bottom: -6px; 
    left: -6px; 
    border: 4px ridge #bbb5c64b;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }
  .eventConDivSafari.dark::before {
    content: "";
    position: absolute;
    top: -5px; 
    right: -6px;
    bottom: -5px; 
    left: -4.5px; 
    border: 3px ridge #474d51c0;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }

  .eventConDivFirefox{
    border: 2px double #D7BE3E;
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid #D7BE3E;

    border-left: 1px solid #D7BE3E;

    margin-left: 2.5px;
    margin-top: 4px;

        position: relative;
        border-radius: 6px;
}
.eventConDivFirefox.light{
    border: 4px double #9AA1E1;
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid #9AA1E1;

    border-left: 1px solid #9AA1E1;

    margin-left: 2.5px;
    margin-top: 4px;

        position: relative;
        border-radius: 6px;
}
.eventConDivFirefox.dark{
    border: 4px double rgb(234, 234, 234);
    border-radius: 2px;
    /* border-top: 1.5px solid #1A5042; */
    border-top: 1px solid rgb(234, 234, 234);

    border-left: 1px solid rgb(234, 234, 234);

    margin-left: 2.5px;
    margin-top: 4px;

        position: relative;
        border-radius: 6px;
}
.eventConDivFirefox::before {
    content: "";
    position: absolute;
    top: -6.25px; 
    right: -5px;
    bottom: -7px; 
    left: -6px; 
    border: 4px ridge #bbb5c6;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }
  .eventConDivFirefox.light::before {
    content: "";
    position: absolute;
    top: -5.25px; 
    right: -6px;
    bottom: -8px; 
    left: -4.5px; 
    border: 3px ridge #474d51c0;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }
  .eventConDivFirefox.dark::before {
    content: "";
    position: absolute;
    top: -5.25px; 
    right: -5px;
    bottom: -7px; 
    left: -4.5px; 
    border: 3px ridge #474d51c0;
    /* border-left: 3px ridge #1A5042; */
    border-radius: 7px;
  }

  
.chart:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

.chart:-moz-full-screen {
  width: 100%;
  height: 100%;
}

.chart:-ms-fullscreen {
  width: 100%;
  height: 100%;
}

.chart:fullscreen {
  width: 100%;
  height: 100%;
}

/* GENERAL */

.chart {
  float: left;
  /* max-height: 800px; */
  /* height: 75vh; */
  position: relative;
  width: 100%;
}
/* svg > g.highcharts-annotation > g.highcharts-annotation-labels > g > text{
    color:slategrey!important;
    fill: slategrey !important;
} */
.highcharts-draw-mode {
  cursor: crosshair;
}

.left {
  float: left;
}

.right,
.highcharts-stocktools-toolbar li.right {
  float: right;
}

.highcharts-stocktools-toolbar {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  width: calc(100% - 63px);
}

.highcharts-stocktools-toolbar li {
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  cursor: pointer;
  float: left;
  height: 40px;
  list-style: none;
  margin-right: 2px;
  margin-bottom: 3px;
  padding: 0px;
  position: relative;
  width: auto;
}

.highcharts-stocktools-toolbar li ul {
  display: none;
  left: 0px;
  padding-left: 0px;
  position: absolute;
  z-index: 125;
}

.highcharts-stocktools-toolbar li:hover {
  background-color: #e6ebf5;
}

.highcharts-stocktools-toolbar li:hover ul {
  display: block;
}

.highcharts-stocktools-toolbar li ul li {
  margin-bottom: 0px;
  width: 160px;
}

.highcharts-stocktools-toolbar li > span.highcharts-menu-item-btn {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
  float: left;
  height: 100%;
  width: 40px;
}
.endDropdown.darkOHLC  a.dropdown-toggle.nav-link::after{
  transform: rotate(-90deg)!important;
  color: white !important;
}
.endDropdown.lightOHLC  a.dropdown-toggle.nav-link::after{
  transform: rotate(-90deg)!important;
  color: black !important;
}
.endDropdown div.dropdown-menu.show{
  padding: 2px 5px 2px 5px!important;
  margin: 0!important;
  border-radius: 4px!important;
  background:grey;
  opacity: 0.8;
  transform: translate(-28.5%,3%) scale(0.35,0.4)!important;
}
.highcharts-stocktools-toolbar li > .highcharts-menu-item-title {
  color: #666;
  line-height: 40px;
  font-size: 0.876em;
  padding: 0px 10px 0px 5px;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}
/* .highcharts-plot-background{
  height:fit-content;
} */
div.highcharts-toggle-toolbar.highcharts-arrow-left.highcharts-arrow-right{
    visibility: hidden!important;
    opacity: 0!important;
    width: 0!important;
    height: 0!important;

}
.highcharts-bindings-wrapper .highcharts-arrow-left.highcharts-arrow-right {
    visibility: hidden!important;
}
.highcharts-bindings-wrapper .highcharts-arrow-left { 
    display: none!important;
 }
 /* .btn-group.special {
  display: flex;
}
.special .btn {
  flex: 1
} */
/*<450 dropdown <480 10 <500 10.5 >500 11*/
.btn {
  white-space: nowrap;
  text-align: center!important;
  /* padding:5px!important; */
  vertical-align: middle!important;
  min-height: 0!important;
  height: 20px!important;
  line-height: 5px!important;
  padding: 0!important;
  /* line-height: 40px!important; */
  /* float: none!important;
  display: inline-block!important; */
  min-width: 0!important;
  width: fit-content!important;
  /* transform:scale(0.5)!important; */
}

/*Theme 1 LABEL BUTTONS*/
.theme0 .btn{
  background-color: #c4c9cd80;
  color:black!important;
  border-color: #6c757d28;
  border-bottom-width: 1px!important;

  border-bottom-color: rgba(255, 255, 255, 0.297)!important;
  border-top-width: 1px!important;

  border-top-color: rgba(255, 255, 255, 0.297)!important;
}
.theme0 .leftbtn{
  border-left-color: rgba(255, 255, 255, 0.297)!important;
}
.theme0 .rightbtn{
  border-right-color: rgba(255, 255, 255, 0.297)!important;

}

.theme0 .btn.active,.theme3 .btn.active:hover{
  color:black!important;

  background-color: #2a2f3241;
  border-color: #6c757d28;

}
.theme0 .btn:active {
    background-color: #2a2f327f!important;
    border-color: #6c757d28!important;
      box-shadow: none;
}
.theme0 .btn:hover{
  color:white!important;

  background-color: #3c42467e;
  border-color: #6c757d28;

}

/*Theme 1 LABEL BUTTONS*/
.theme1 .btn{
  background-color: #c4c9cd80;
  color:black!important;
  border-color: #6c757d28;
  border-bottom-width: 1px!important;

  border-bottom-color: rgba(255, 255, 255, 0.297)!important;
  border-top-width: 1px!important;

  border-top-color: rgba(255, 255, 255, 0.297)!important;
}
.theme1 .leftbtn{
  border-left-color: rgba(255, 255, 255, 0.297)!important;
}
.theme1 .rightbtn{
  border-right-color: rgba(255, 255, 255, 0.297)!important;

}

.theme1 .btn.active,.theme3 .btn.active:hover{
  color:black!important;

  background-color: #2a2f3241;
  border-color: #6c757d28;

}
.theme1 .btn:active {
    background-color: #2a2f327f!important;
    border-color: #6c757d28!important;
      box-shadow: none;
}
.theme1 .btn:hover{
  color:white!important;

  background-color: #3c42467e;
  border-color: #6c757d28;

}


/*Theme 2 LABEL BUTTONS*/
.theme2 .btn{
  background-color: #c4c9cd80;
  color:black!important;
  border-color: #6c757d28;
  border-bottom-width: 1px!important;

  border-bottom-color: rgba(255, 255, 255, 0.297)!important;
  border-top-width: 1px!important;

  border-top-color: rgba(255, 255, 255, 0.297)!important;
}
.theme2 .leftbtn{
  border-left-color: rgba(255, 255, 255, 0.297)!important;
}
.theme2 .rightbtn{
  border-right-color: rgba(255, 255, 255, 0.297)!important;

}

.theme2 .btn.active,.theme3 .btn.active:hover{
  color:white!important;

  background-color: #2a2f327f;
  border-color: #6c757d28;

}
.theme2 .btn:active {
    background-color: #2a2f327f!important;
    border-color: #6c757d28!important;
      box-shadow: none;
}
.theme2 .btn:hover{
  background-color: #3c42467e;
  border-color: #6c757d28;

}

/*Theme 3 LABEL BUTTONS*/
.theme3 .btn{
  background-color: #6c757d28;
  border-color: #6c757d28;
  border-bottom-width: 1px!important;

  border-bottom-color: rgba(255, 255, 255, 0.297)!important;
  border-top-width: 1px!important;

  border-top-color: rgba(255, 255, 255, 0.297)!important;
}
.theme3 .leftbtn{
  border-left-color: rgba(255, 255, 255, 0.297)!important;
}
.theme3 .rightbtn{
  border-right-color: rgba(255, 255, 255, 0.297)!important;

}

.theme3 .btn.active,.theme3 .btn.active:hover{
  background-color: #2a2f327f;
  border-color: #6c757d28;

}
.theme3 .btn:active {
    background-color: #2a2f327f!important;
    border-color: #6c757d28!important;
      box-shadow: none;
}
.theme3 .btn:hover{
  background-color: #3c42467e;
  border-color: #6c757d28;

}

.btn10{
  font-size: 10px!important;
}
.btn105{
  font-size: 10.5px!important;
}
.btn11{


  font-size: 11px!important;
}
.d-flex, .btn-group{
  /* justify-content: center!important; */
  /* min-height: 20px!important;
  max-height:20px!important; */
  border: 1px solid black!important;

}

.dropdown-toggle{
  background-color: transparent!important;
  border: none!important;
  font-size: 13px!important;

}
.theme1 .dropdown-toggle{
  background-color: transparent!important;
  border: none!important;
  color:black!important;
  font-size: 13px!important;
  
}
.smallDropdownTitle  .dropdown-toggle{
  font-size: 9px!important;
}
.medDropdownTitle  .dropdown-toggle{
  font-size: 12px!important;
}
.d-flex.dropdown{
 justify-content: flex-end!important;
  border: none!important;
  
}
.dropdown-item {
    padding: 0px 5px !important;
    font-size: 12px !important;
    text-align: left!important;
    color:white!important;

}
.dropdown-item:hover {
  background: rgb(94, 94, 94)!important;

}
.dropdown-item.active {
  background-color: rgba(120, 120, 120, 0.365)!important;
}
.dropdown-menu {
  /* background-color: rgba(72, 65, 65, 0.845)!important; */
  background-color: #3c3939c7!important;

  min-width: 75px !important;
}
