    .dropdown-toggle{
    background-color: transparent!important;
    border: none!important;
    font-size: 13px!important;
  
  }
  .theme0 .dropdown-toggle{
    background-color: transparent!important;
    border: none!important;
    color:white!important;
    font-size: 13px!important;
    
  }

  .theme1 .dropdown-toggle{
    background-color: transparent!important;
    border: none!important;
    color:black!important;
    font-size: 13px!important;
    
  }
    .theme1 .dropdown-toggle.btn:hover{

    color:rgb(90, 90, 90)!important;
    
  }

  .theme2 .dropdown-toggle{
    background-color: transparent!important;
    border: none!important;
    color:rgb(226, 230, 237)!important;
    font-size: 13px!important;
    
  }
    .theme2 .dropdown-toggle.btn:hover{

    color:rgb(0, 0, 0)!important;
    
  }
  
  .smallDropdownTitle  .dropdown-toggle{
    font-size: 9px!important;
  }
  .medDropdownTitle  .dropdown-toggle{
    font-size: 12px!important;
  }
  .d-flex.dropdown{
   justify-content: flex-end!important;
    border: none!important;
    
  }
  .dropdown-item {
      padding: 0px 5px !important;
      font-size: 12px !important;
      text-align: left!important;
      color:white!important;
  
  }
  .dropdown-item:hover {
    background: rgb(94, 94, 94)!important;
  
  }
  .dropdown-item.active {
    background-color: rgba(120, 120, 120, 0.365)!important;
  }
  .dropdown-menu {
    /* background-color: rgba(72, 65, 65, 0.845)!important; */
    background-color: #3c3939c7!important;
  
    min-width: 75px !important;
  }
  